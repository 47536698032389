import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout/Layout"
import styles from "../styles/digitalKitPage.module.scss"
import tt from "../helpers/translation"
import ArrowBlue from "../assets/arrow-blue-left.svg"
import WhiteSection from "../components/whiteSection/whiteSection"
import { SeeMoreButton } from "../components/seeMoreButton/seeMoreButton"

const KitDigitalDetailPage = ({ data, pageContext }) => {
  const infoPage = data.allDigitalKitFichaJson.edges[0].node
  const [resultUrls] = pageContext.urls.filter(obj => {
    return obj.id === data.allDigitalKitFichaJson.edges[0].node.name
  })
  const { seoDataJson } = data
  const seoData = {
    url: seoDataJson.url,
    titleSeo: seoDataJson.title,
    description: seoDataJson.description,
    alternateLanguage: seoDataJson.alternateLanguage,
    alternateUrl: seoDataJson.alternateUrl,
  }

  const previousPage = () => {
    if (pageContext.lang === "ca") {
      return "/ca/kit-digital/"
    }
    if (pageContext.lang === "es") {
      return "/es/kit-digital/"
    }
    return "/digital-kit/"
  }

  return (
    <Layout
      leftContactUs
      lan={data.allDigitalKitFichaJson.edges[0].node.lang}
      location="digitalKit"
      urls={resultUrls}
      contrast={true}
      seoData={seoData}
    >
      <WhiteSection
        careersClass
        firstTitle={infoPage.title}
        subtitle={infoPage.subtitle}
      >
        <div className={styles.white_section_container}>
          <div className={styles.content_container}>
            <div className={styles.title_with_button}>
              <h3>{infoPage.funcionalities}</h3>
              <Link
                to={previousPage()}
                className={`${styles.button} ${styles.back}`}
              >
                <ArrowBlue />
                <span>{tt("Volver a Kit Digital", pageContext.lang)}</span>
              </Link>
            </div>
            <div className={styles.text_container}>
              <p>{infoPage.p_1 || null}</p>
              {infoPage.seg_1 ? (
                <ul>
                  <li>{infoPage.seg_1}</li>
                  <li>{infoPage.seg_2}</li>
                  {infoPage.seg_3 ? <li>{infoPage.seg_3}</li> : null}
                </ul>
              ) : null}
              <p>{infoPage.p_2 || null}</p>
              <ol>
                <li>
                  <strong>{infoPage.punto_01}</strong> {infoPage.punto_011}
                </li>
                <li>
                  <strong>{infoPage.punto_02}</strong> {infoPage.punto_022}
                </li>
                <li>
                  <strong>{infoPage.punto_03}</strong> {infoPage.punto_033}
                </li>
                <li>
                  <strong>{infoPage.punto_04}</strong> {infoPage.punto_044}
                </li>
                {infoPage.punto_05 ? (
                  <li>
                    <strong>{infoPage.punto_05}</strong> {infoPage.punto_055}
                  </li>
                ) : null}
                {infoPage.punto_06 ? (
                  <li>
                    <strong>{infoPage.punto_06}</strong> {infoPage.punto_066}
                  </li>
                ) : null}
                {infoPage.punto_07 ? (
                  <li>
                    <strong>{infoPage.punto_07}</strong> {infoPage.punto_077}
                  </li>
                ) : null}
                {infoPage.punto_08 ? (
                  <li>
                    <strong>{infoPage.punto_08}</strong> {infoPage.punto_088}
                  </li>
                ) : null}
                {infoPage.punto_09 ? (
                  <li>
                    <strong>{infoPage.punto_09}</strong> {infoPage.punto_099}
                  </li>
                ) : null}
              </ol>
              <p>{infoPage.p_3}</p>
              <p>
                <strong>{infoPage.rango_titulo}</strong>{" "}
                {infoPage.rango_numeros}
              </p>
            </div>
          </div>
        </div>
      </WhiteSection>
      <div className={styles.blue_section_container}>
        <div className={styles.first_section}>
          <div className={styles.blue_text_container}>
            <p>{tt("¿Quieres conocer nuestro trabajo?", infoPage.lang)}</p>
            <SeeMoreButton
              text={tt("What we do", infoPage.lang)}
              sendTo={tt("/what-we-do/", infoPage.lang)}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = () => graphql`
  query digitalKitFichaQuery($lang: String!, $pageName: String!) {
    seoDataJson(lang: { eq: $lang }, name: { eq: $pageName }) {
      url
      title
      description
      alternateLanguage
      alternateUrl
    }
    allDigitalKitFichaJson(
      filter: { lang: { eq: $lang }, name: { eq: $pageName } }
    ) {
      edges {
        node {
          name
          url
          lang
          title
          subtitle
          funcionalities
          p_1
          seg_1
          seg_2
          seg_3
          p_2
          punto_01
          punto_011
          punto_02
          punto_022
          punto_03
          punto_033
          punto_04
          punto_044
          punto_05
          punto_055
          punto_06
          punto_066
          punto_07
          punto_077
          punto_08
          punto_088
          punto_09
          punto_099
          p_3
          rango_titulo
          rango_numeros
        }
      }
    }
  }
`

export default KitDigitalDetailPage
